<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 46 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Subscriptions list</title>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.81042 6V42H41.9145V6H3.81042ZM19.0521 34H9.52604V30H19.0521V34ZM19.0521 26H9.52604V22H19.0521V26ZM19.0521 18H9.52604V14H19.0521V18ZM28.2351 30L22.8625 24.32L25.5488 21.5L28.2351 24.34L34.2746 18L36.98 20.84L28.2351 30Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.81042 6V42H41.9145V6H3.81042ZM19.0521 34H9.52604V30H19.0521V34ZM19.0521 26H9.52604V22H19.0521V26ZM19.0521 18H9.52604V14H19.0521V18ZM28.2351 30L22.8625 24.32L25.5488 21.5L28.2351 24.34L34.2746 18L36.98 20.84L28.2351 30Z"
      :fill="color"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2795_19650"
        x1="266.147"
        y1="-139.636"
        x2="46.8948"
        y2="74.4485"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#6E19F0" />
        <stop
          offset="1"
          stop-color="#6E19F0"
          stop-opacity="0"
        />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  name: 'ic-subscriptions',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
    color: {
      type: String,
    },
  },
}
</script>
